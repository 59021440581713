<template lang="pug">
transition(name="fade")
  om-toast.mb-5.mt-2.failed-integration-notification(
    v-if="errorCount > 0"
    :closable="false"
    color="warning"
    type="side"
  )
    .d-flex.align-items-center.w-100
      .font-weight-bold {{ $t('failedIntegration.notification.title', { count: errorCount }) }}
      .notification-action-wrapper
        om-button.ml-3(secondary @click="openModal") {{ $t('failedIntegration.notification.resolve') }}
</template>
<script>
  export default {
    props: {
      errorCount: { type: Number, default: 0 },
    },
    methods: {
      openModal() {
        this.$modal.show('failed-integration-resync');
      },
    },
  };
</script>
