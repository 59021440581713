<template lang="pug">
div
  om-modal(
    name="integration-error-details"
    modalClasses="integration-error-details-modal"
    :width="800"
    color="light"
    @beforeOpen="beforeOpen"
    :scrollable="true"
  )
    template(slot="modal-header")
      .row
        .col
          .font-weight-bold.font-size-1--25 {{ $t('integrationErrorDetailsModal.title') }}
      .brand-modal-action-icon.cursor-pointer(@click="$modal.hide('integration-error-details')")
        close-icon(:width="12" :height="12" color="#AAB1C1")
    template(slot="modal-body" v-if="currentError")
      brand-tabs(:showTabs="true" :active="0" v-if="errors.length > 1")
        brand-tab(v-for="error in errors" :key="error._id" :name="getIntegrationErrorTab(error)")
          .integration-box
            integration-logo(
              style="min-width: 62px"
              :type="errorIntegrationType(error.integrationId)"
            )
            .integration-box-text.ml-3
              .integration-name.font-weight-bold {{ isDeleted(error.integrationId) ? $t(`integrations.deleted.name`) : $t(`integrations.${errorIntegrationType(error.integrationId)}.name`) }}
              .integration-details.font-size-0--875.mt-2(v-html="createText(error.integrationId)")
          highlight.mb-0(
            v-show="error.reason !== '' && error.details !== '[]'"
            lang="js"
            :codeStr="error.details ? error.details : ''"
            :copyToClipboard="false"
          )
          hr.mt-5
          .d-flex.flex-column.align-items-center.mt-4
            button.brand-btn.brand-btn-primary(
              @click="retryFailedIntegration(error)"
              :disabled="loading"
              :class="{ loading }"
            ) {{ $t('integrationErrorDetailsModal.retryBtn') }}
            .brand-link-ternary.brand-link-ternary-plain.text-center.mt-2(
              @click="$modal.hide('integration-error-details')"
            ) {{ $t('close') }}
      div(v-else)
        .integration-box
          integration-logo(
            style="min-width: 62px"
            :type="errorIntegrationType(errors[0].integrationId)"
          )
          .integration-box-text.ml-3
            .integration-name.font-weight-bold(
              v-if="errorIntegrationType(errors[0].integrationId)"
            ) {{ $t(`integrations.${errorIntegrationType(errors[0].integrationId)}.name`) }}
            .integration-name.font-weight-bold(v-else) {{ $t(`integrations.deleted.name`) }}
            .integration-details.font-size-0--875.mt-2(
              v-html="createText(errors[0].integrationId)"
            )
        highlight.mb-0(
          v-show="errors[0].reason != '\"\"' && errors[0].reason !== '' && errors[0].details !== '[]'"
          lang="js"
          :codeStr="errors[0].details"
          :copyToClipboard="false"
        )
        hr.mt-5
        .d-flex.flex-column.align-items-center.mt-4
          om-button(primary @click="retryFailedIntegration(errors[0])" :loading="loading") {{ $t('integrationErrorDetailsModal.retryBtn') }}
          .brand-link-ternary.brand-link-ternary-plain.text-center.mt-2(
            @click="$modal.hide('integration-error-details')"
          ) {{ $t('close') }}
</template>

<script>
  import RETRY_FAILED_SUBSCRIPTION from '@/graphql/RetryFailedSubscription.gql';
  import DELETE_FAILED_SUBSCRIPTION from '@/graphql/DeleteFailedSubscription.gql';
  import Highlight from '@/components/Highlight.vue';
  import IntegrationLogo from '@/components/IntegrationLogo.vue';
  import { mapGetters } from 'vuex';
  import { uniqWith } from 'lodash-es';
  import { track } from '@/services/xray';

  export default {
    components: {
      Highlight,
      IntegrationLogo,
    },

    data() {
      return {
        loading: false,
        errors: [],
        selectedError: null,
        campaignId: null,
        allErrors: [],
      };
    },

    computed: {
      ...mapGetters(['integrations', 'databaseId']),
      currentError() {
        if (this.selectedError !== null) {
          return this.errors[this.selectedError];
        }

        return null;
      },
    },

    methods: {
      getIntegrationErrorTab(error) {
        const isDeleted = this.isDeleted(error.integrationId);
        if (isDeleted) {
          return `${this.$t('integrations.deleted.name').toLowerCase()} ${
            error.index ? ` #${error.index}` : ''
          }`;
        }

        return `${this.$t(
          `integrations.${this.errorIntegrationType(error.integrationId)}.name`,
        )} (${this.errorIntegrationName(error.integrationId)})${
          error.index ? ` #${error.index}` : ''
        }`;
      },
      beforeOpen(event) {
        this.errors = [];
        this.allErrors = event.params.errors;
        this.selectedError = null;

        if (event.params) {
          event.params.errors.forEach((error) => {
            error.details = this.createJSONError(error.reason);
          });
          const errors = uniqWith(
            event.params.errors,
            (errorA, errorB) =>
              JSON.stringify(errorA.details) === JSON.stringify(errorB.details) &&
              errorA.integrationId === errorB.integrationId,
          );
          const integrationIds = errors.map((error) => error.integrationId);
          const indexes = {};
          errors.forEach((error) => {
            const duplicatedIds = integrationIds.filter((id) => id === error.integrationId);
            if (duplicatedIds.length > 1) {
              if (indexes[error.integrationId]) {
                error.index = indexes[error.integrationId] + 1;
                indexes[error.integrationId]++;
              } else {
                error.index = 1;
                indexes[error.integrationId] = 1;
              }
            }
          });

          this.errors = errors;
          this.campaignId = event.params.campaignId;

          if (this.errors.length > 0) {
            this.selectedError = 0;
          }
        }
      },
      getIntegrationText(integrationId) {
        return `integrations.${this.errorIntegrationType(integrationId)}.name`;
      },
      isDeleted(integrationId) {
        if (!this.$te(this.getIntegrationText(integrationId))) return true;

        return false;
      },
      createText(integrationId) {
        const campaignSettingRoute = this.$router.resolve({
          name: 'campaign_settings',
          params: { userId: this.databaseId, id: this.campaignId },
          query: { editIntegrationId: integrationId },
        });
        let integrationName = this.$t(this.getIntegrationText(integrationId));

        if (this.isDeleted(integrationId)) {
          integrationName = this.$t('integrations.deleted.name').toLowerCase();
        }
        return this.$t('integrationErrorDetailsModal.text', {
          integration: integrationName,
          integrationLink: campaignSettingRoute.href,
          support: this.$t('integrationErrorDetailsModal.support', {
            integrationName,
          }),
        });
      },
      createJSONError(reason) {
        const keywords = ['title', 'detail', 'details', 'message', 'messages', 'error', 'errors'];
        try {
          let jsonStr = '';
          const err = JSON.parse(reason);
          keywords.forEach((keyword) => {
            if (err.hasOwnProperty(keyword)) {
              jsonStr += JSON.stringify(
                {
                  [keyword]: err[keyword],
                },
                null,
                4,
              );
            }
          });
          return jsonStr === '' ? err : jsonStr;
        } catch (error) {
          return reason;
        }
      },
      errorIntegrationType(integrationId) {
        if (this.currentError) {
          const integration = this.integrations.find(({ _id }) => _id === integrationId);
          return integration ? integration.type : null;
        }

        return null;
      },
      errorIntegrationName(integrationId) {
        const integration = this.integrations.find(({ _id }) => _id === integrationId);
        return integration ? integration.data.name : '';
      },
      setSelectedError(index) {
        this.selectedError = index;
      },
      async retryFailedIntegration(param) {
        track('integrationErrorResend');
        try {
          this.loading = true;
          const duplicated = [];
          for (const allErrorItem of this.allErrors) {
            if (
              allErrorItem.integrationId === param.integrationId &&
              allErrorItem.details === param.details
            ) {
              duplicated.push(allErrorItem);
            }
          }
          const {
            data: { retryFailedSubscription: error },
          } = await this.$apollo.mutate({
            mutation: RETRY_FAILED_SUBSCRIPTION,
            variables: { id: duplicated[0]._id },
          });

          for (let i = 1; i < duplicated.length; i++) {
            this.$apollo.mutate({
              mutation: DELETE_FAILED_SUBSCRIPTION,
              variables: { id: duplicated[i]._id },
            });
          }

          if (!error) {
            // frontend deleted the failed-entry because it succeeded.
            this.$modal.hide('integration-error-details');
            this.$notify({
              type: 'success',
              text: this.$t(`notifications.subscriptionRetrySuccess`),
            });
            this.$emit('refetchSubscribers');
          } else {
            // failed subscription entry still exists, refresh error message
            this.$notify({
              type: 'error',
              text: this.$t(`notifications.subscriptionRetriedNoSuccess`),
            });
          }
        } catch (err) {
          this.$notify({
            type: 'error',
            text: this.$t(`notifications.subscriptionRetryError`),
          });
        } finally {
          this.loading = false;
        }
      },
    },
  };
</script>
<style lang="sass">
  .custom-class
    margin-left: 50px
  .integration-error-details-modal
    .brand-modal
      min-height: 100%
      .brand-modal-header
        padding-top: 1.25rem
        padding-left: 2.8125rem
        padding-bottom: 1.25rem
        padding-right: 2.8125rem
        border-bottom: 1px solid #E9EFF4

        .brand-modal-action-icon
          top: 1.3125rem
          right: 1rem
      .brand-modal-body
        padding: 1.5rem 2.8125rem !important
        padding-bottom: 0 !important
        height: 100%
        font-size: 14px

        .brand-tab
          width: 100%

        .insert-link
          flex: 0 0 6.3rem

      .brand-modal-footer
        padding: 0 !important
        margin: 0 !important
        padding-top: 2.5rem !important
        padding-bottom: 1.5625rem !important

    .last-try-timestamp
      font-size: 12px

    .integration-box
      display: flex
      align-items: center

      .integration-box-text
        font-size: 1.2rem
</style>
