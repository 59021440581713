<template lang="pug">
.user-segments(v-if="!loading")
  empty-state(
    v-if="!segments.length"
    :text1="$t('emptyState.segments.text1')"
    :text2="$t('emptyState.segments.text2')"
    :imageUrl="require('@/assets/empty-state/segments.png')"
    split-ratio="half"
    @show="segmentsEmptyStateShowed"
  )
    template(#underText)
      om-link(primary withIconRight @click="handleSegmentsEmptyStateCTAClick") {{ $t('emptyState.segments.link') }}
  .user-segments-list.mt-6.pt-6(v-else)
    om-table.user-segments-table.mt-5(
      :items="fixedNamedSegments"
      :columns="columns"
      :columnClasses="{ createdAt: 'user-segment-created-at-th', actions: 'user-segment-actions-th' }"
      :serverPaginate="false"
      :selectable="false"
      :serverSort="false"
      :addSubscriberClasses="false"
      @mouseOutFromTableRow="closeKebabMenu($event)"
    )
      router-link(slot="name" slot-scope="{ rowData }" :to="getEditRoute(rowData.row._id)") {{ rowData.row.name }}
      .d-flex.justify-content-end(slot="createdAt" slot-scope="{ rowData }") {{ formatDate(rowData.row.createdAt) }}
      .d-flex.justify-content-end(slot="actions" slot-scope="{ rowData }")
        om-kebab-menu(
          :rowData="rowData"
          :ref="`kebab_${rowData.row._id}`"
          @edit="goToEditRoute(rowData.row._id)"
          @delete="deleteSegment(rowData.row)"
        )
          template(slot="edit")
            span {{ $t('edit') }}
          template(slot="delete")
            span {{ $t('delete') }}
</template>

<script>
  import { mapMutations } from 'vuex';
  import moment from 'moment';
  import { track } from '@/services/xray';
  import { UilEllipsisV } from '@iconscout/vue-unicons';
  import EmptyState from '@/components/EmptyState.vue';
  import SEGMENTS from '@/graphql/Segments.gql';
  import DELETE_SEGMENT from '@/graphql/DeleteSegment.gql';
  import { getBrandedClassString } from '@/components/Elements/Button';
  import closeKebabMenu from '@/components/Elements/KebabMenu/closeKebabMenu';

  const DATE_FORMATS = {
    hu: 'YYYY-MM-DD',
    en: 'MMMM DD, YYYY',
  };

  export default {
    components: { UilEllipsisV, EmptyState },
    mixins: [closeKebabMenu],
    props: {
      changed: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      loading: true,
      segments: [],
    }),
    computed: {
      columns() {
        return [
          { header: this.$t('name'), key: 'name' },
          { header: this.$t('created'), key: 'createdAt' },
          { header: '', key: 'actions' },
        ];
      },
      fixedNamedSegments() {
        return this.segments.map((segment) => ({
          ...segment,
          name: segment.name[this.$i18n.locale],
        }));
      },
    },
    apollo: {
      segments: {
        query: SEGMENTS,
        variables: { type: 'userdefined' },
        result() {
          this.loading = false;
          this.showAdminLoader(false);
        },
      },
    },
    methods: {
      ...mapMutations(['showAdminLoader']),
      formatDate(date) {
        const format = DATE_FORMATS[this.$i18n.locale];
        return moment(date).format(format);
      },
      goToEditRoute(id) {
        const route = this.getEditRoute(id);
        this.$router.push(route);
      },
      getEditRoute(id) {
        this.closeKebabMenu(id);
        const { userId } = this.$route.params;
        return {
          name: 'edit_segment',
          params: { userId, id },
        };
      },
      deleteSegment({ _id: id, type }) {
        const variables = { id, type };
        this.$modal.show('dialog', {
          text: this.$t('confirmationDialog'),
          buttons: [
            {
              title: this.$t('yes'),
              class: 'btn btn-primary mr-3 steve',
              handler: () => {
                this.$apollo
                  .mutate({
                    mutation: DELETE_SEGMENT,
                    variables,
                  })
                  .then(async () => {
                    await this.$apollo.queries.segments.refresh();
                    this.$modal.hide('dialog');
                  })
                  .catch(console.error);
              },
            },
            {
              title: this.$t('cancel'),
              class: getBrandedClassString({ secondary: true }),
              default: true,
            },
          ],
        });
      },
      segmentsEmptyStateShowed() {
        track('segmentsEmptyStateShow');
      },
      handleSegmentsEmptyStateCTAClick() {
        track('segmentsEmptyStateCTAClicked');
        const url = this.$t('emptyState.segments.linkUrl');
        return window.open(url, '_blank');
      },
    },
  };
</script>

<style lang="sass">
  @import '../../sass/variables/_colors'

  .user-segments-table
    .brand-table
      .thead
        .tr
          .th
            display: flex
            align-items: flex-end
            text-align: left
            span
              padding: 0 1rem .5rem
              justify-content: flex-start
      .tbody
        .tr
          padding: 0
          min-height: 4.5rem
          .td
            padding-left: 1rem
            text-align: left
    .user-segment
      &-created-at-th
        justify-content: end
        .cursor-pointer
          padding-right: 0 !important
      &-actions-th
        flex-grow: 0 !important
        min-width: 4rem
</style>
