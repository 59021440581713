import { render, staticRenderFns } from "./Modal.vue?vue&type=template&id=27f6f3a1&scoped=true&lang=pug&"
import script from "./Modal.vue?vue&type=script&lang=js&"
export * from "./Modal.vue?vue&type=script&lang=js&"
import style0 from "./Modal.vue?vue&type=style&index=0&id=27f6f3a1&lang=sass&scoped=true&"
import style1 from "./Modal.vue?vue&type=style&index=1&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27f6f3a1",
  null
  
)

export default component.exports