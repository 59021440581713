<template lang="pug">
om-modal.failed-integration-resync(
  :name="name"
  :width="langWidth()"
  color="light"
  scrollable
  :clickToClose="true"
  :lang="$i18n.locale"
)
  template(slot="modal-header")
    .brand-modal-action-icon
      close-icon.cursor-pointer(
        @click.native="$modal.hide(name)"
        :width="14"
        :height="14"
        color="#C2C2C2"
      )
    om-heading(h5) {{ integrationResyncData.length ? $t('failedIntegration.modal.title') : $t('failedIntegration.modal.allDone') }}
    .failed-integration-resync-date-tooltip {{ $t('failedIntegration.modal.dateTooltip') }}
  template(slot="modal-body")
    .failed-integration-resync-body
      .integration-resync-row(v-if="integrationResyncData.length")
        template(v-for="rowData in integrationResyncData")
          .row-item.d-flex.bb-1
            resync-row(
              v-if="rowData.errorCount"
              :rowData="rowData"
              :failedIntegrationResyncService="failedIntegrationResyncService"
              @integration-resync="resync"
            )
          .row-item.d-flex.bb-1(v-for="log in rowData.logs")
            resync-row(
              type="log"
              :rowData="rowData"
              :logData="log"
              :failedIntegrationResyncService="failedIntegrationResyncService"
            )
      .d-flex.justify-content-center.align-items-center(v-else)
        img.monk(:src="require('@/assets/admin/svg/happy-monk.svg')")
</template>
<script>
  import ResyncRow from './ResyncRow.vue';

  export default {
    components: { ResyncRow },
    props: {
      failedIntegrationResyncService: { type: Object },
      integrationResyncData: { type: Array },
    },
    data: () => ({
      name: 'failed-integration-resync',
    }),
    methods: {
      resync(params) {
        this.$emit('integration-resync', params);
        this.$modal.hide(this.name);
      },
      langWidth() {
        return this.$i18n.locale === 'hu' ? 920 : 820;
      },
    },
  };
</script>
<style lang="sass" scoped>
  @import '@/sass/variables/_colors.sass'
  ::v-deep .brand-modal-body
    padding: 1.715rem 2.5rem
    .failed-integration-resync-body
      margin-right: -40px
      .integration-resync-row
        max-height: 500px
        overflow: auto
        padding-right: 40px
        .row-item
          .row-item-left-section
            font-size: 14px
            width: 48%
            min-width: auto
            :lang(hu) &
              width: 38%
          .row-item-center-section
            font-size: 14px
            color: $om-gray-700
            padding: 0 10px
          .row-item-right-section
            min-width: 10rem
            width: 40%
            justify-content: flex-end
            :lang(hu) &
              width: 51%
            .row-item-date-range
              font-size: 14px
              color: $om-gray-700
              margin-inline: auto
            .btn
              max-height: 32px
            .custom-button
              cursor: initial
              &.done
                background-color: $om-alert-green-200
                color: $om-alert-green-600
              &.re-sync
                background-color: $om-gray-200
                color: $om-gray-700
                font-weight: 400
              &:hover
                text-decoration: none
  ::v-deep .brand-modal-footer
    padding: 0 2.857rem
    margin-bottom: unset
  ::v-deep .failed-integration-resync-date-tooltip
    font-size: 13px
    margin-top: 5px
</style>
<style lang="sass">
  @media screen and(max-width: 991px)
    .failed-integration-resync .v--modal-box
      width: 80% !important
</style>
